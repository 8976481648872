@font-face {
    font-family: 'equiplight';
    src: url('../fonts/Hoftype - Equip-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'equipmedium';
    src: url('../fonts/Hoftype - Equip-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'equipthin';
    src: url('../fonts/Hoftype - Equip-Thin.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'equipextralight';
    src: url('../fonts/Hoftype - Equip-ExtraLight.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}